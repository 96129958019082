document.addEventListener('DOMContentLoaded', () => {
  // selector variables
  const zipcodeButtonSelector = '.js-zipcode-button', // zipcode button on the global nav
        zipcodeButtonSpanSelector = '.js-zipcode-span', // span where the zipcode appears on the global nav
        zipInputNavSelector = '.js-zipcode-modal', // zipcode modal
        zipcodeModalContainerSelector = '.js-zipcode-modal-container',
        zipcodeSearchButtonSelector = '.js-zipcode-search', // Search button next to the input element
        zipcodeInputSelector = '.js-zipcode-input', // modal zipcode input
        zipInputNavCloseSelector = '.js-close-zipcode-modal', // close zip input
        zipInputClearBtnSelector = '.js-clear-zipcode-modal-input', // clear the zip input
        zipErrorMessageSelector = '.js-zipcode-error-message', // error message
        zipInputContainerSelector = '.js-zipcode-input-container'; // entire element that contains the input element

  // Global variables
  let $zipcodeButton = document.querySelector(zipcodeButtonSelector),
      $zipcodeButtonSpan = $zipcodeButton.querySelector(zipcodeButtonSpanSelector),
      $zipcodeModal = document.querySelector(zipInputNavSelector),
      $zipcodeSearchButton = $zipcodeModal.querySelector(zipcodeSearchButtonSelector),
      $zipcodeInput = $zipcodeModal.querySelector(zipcodeInputSelector),
      $errorMessage = $zipcodeModal.querySelector(zipErrorMessageSelector),
      $zipInputContainer = $zipcodeModal.querySelector(zipInputContainerSelector),
      $zipInputNavClose = $zipcodeModal.querySelector(zipInputNavCloseSelector),
      $zipcodeModalContainer = $zipcodeModal.querySelector(zipcodeModalContainerSelector),
      $zipInputClearBtn = $zipcodeModal.querySelector(zipInputClearBtnSelector);

  var firstFocusableElement,
      lastFocusableElement,
      securityCheck,
      elementsInsideModal = $zipcodeModal.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'),
      regionA = ['CA'];

    // Open the zipcode modal
  function openForm() {
    $zipcodeModal.classList.remove('hidden');
    firstFocusableElement = elementsInsideModal[0];
    lastFocusableElement = elementsInsideModal[elementsInsideModal.length - 1];
    $zipcodeModalContainer.addEventListener('keydown', handleKeyDown);
    isLocalStorageAvailable();
  };

  // Close the zipcode modal
  function closeForm() {
    clearZipcodeInput();
    $zipcodeModal.classList.add('hidden');
    $zipInputNavClose.classList.remove('hidden');
  };

  // function that search the zipcode user write on input filed
  function searchZipCode() {
    var inputValue = $zipcodeInput.value;
    $errorMessage.classList.remove('active');

    if (inputValue) {
        getLatLonFromQuery(inputValue, getLatValues);
      }
  };

  // Gets the coordinates of the zipcode
  function getLatLonFromQuery(query, callback) {
    var request = "https://dev.virtualearth.net/REST/v1/Locations?q=" + encodeURIComponent(query) + "&key=" + "Ap0AObt84NcDaUThCeWOj52ZqUHv6k4TJhjLibR-DghC-semgoj-0uPbIi8r0E4j";
    callRestService( request,
    function (res) {
      if (res.resourceSets["0"].estimatedTotal > 0) {
        var resources = res.resourceSets["0"].resources;
        var resource;
        var currentState = resources[0].address.adminDistrict;

        if (currentState) {
          if (regionA.indexOf(currentState) != -1) {
            localStorageSetItemCommon("_GeoCurrentState", currentState);
            localStorageSetItemCommon("_GeoCurrentRegion", "A");
          } else {
            localStorageSetItemCommon("_GeoCurrentState", currentState);
            localStorageSetItemCommon("_GeoCurrentRegion", "B");
          }
        }
        for (var i = 0; i < resources.length; i++) {
          if (resources[i].entityType.indexOf("Postcode") >= 0) {
            resource = resources[i];
            break;
          }
        }
        if (resource == null) {
          resource = resources["0"];
        }
        callback(
          resource.geocodePoints["0"].coordinates["0"],
          resource.geocodePoints["0"].coordinates["1"],
          resource.address.countryRegion
          );
        } else {
          showError();
        }
      }
    );
  }

  // Save the variables in the local storage
  function localStorageSetItemCommon(key, value) {
    if (securityCheck) {
      // use fallback cookies
      cookieStorage.setCookie(key, value);
    } else {
      //use localStorage
      localStorage.setItem(key, value);
    }
  }

  // use cookies when LocalStorage is not permitted
  const cookieStorage = {
    getCookie(key) {
      const cookieValue = Cookies.get(key);
      return cookieValue || false; // Return false if cookie is undefined
    },
    setCookie(key, value) {
      Cookies.set(key, value);
    },
  };

  const setZip = {
    updateAll(state, zipCode, lat, lon, ev) {
      this.updateLocalStorageZip(zipCode);
      this.updateLocalStorageState(state);
      this.updateLocalStoragePositions(lat, lon);
      this.updateNavZip(zipCode);

      notifyOtherPage(zipCode);

    },

    updateNavZip(zipCode) {
      zipCode = zipCode.split('-')[0]; // Clean up zip code to just 5 digits
      $zipcodeButtonSpan.textContent = zipCode; // update zipcode on the global nav
      clearZipcodeInput();
      closeForm();
    },

    updateLocalStorageZip(zipCode) {
      if (typeof Storage !== 'undefined' && zipCode) {
        localStorage.setItem('_GeoCurrentZipCode', zipCode);
      } else {
        console.warn('Your browser does not support Web Storage');
      }
    },

    updateLocalStorageState(state) {
      if (typeof Storage !== 'undefined' && state) {
        localStorage.setItem('_GeoCurrentState', state);
        localStorage.setItem(
          '_GeoCurrentRegion',
          regionA.indexOf(state) !== -1 ? 'A' : 'B'
        );
      } else {
        console.warn('Your browser does not support Web Storage');
      }
    },

    updateLocalStoragePositions(lat, long) {
      if (typeof Storage !== 'undefined' && lat !== null && long !== null) {
        localStorage.setItem('_GeoCurrentLat', lat);
        localStorage.setItem('_GeocurrentLong', long);
        localStorage.setItem('_GeoTempLat', lat);
        localStorage.setItem('_GeoTempLong', long);
      }
    },
  };

  // Check if the local storage is available in the browser
  function isLocalStorageAvailable() {
    try {
      const storage = window.localStorage,
            testKey = 'test';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      securityCheck = true;
    } catch (e) {
      securityCheck = false;
    }
  }

  // Get latitude and longitude values just if the zipcode is for the United States
  function getLatValues (lat, lon, country) {
      if (country !== "United States") {
        showError();
        return;
      }

      if (typeof Storage !== "undefined" && lat != null && lon != null ) {
          // set temp. lat/long in local storage
          localStorageSetItemCommon("_GeoTempLat", lat);
          localStorageSetItemCommon("_GeoTempLong", lon);
      }

      getPinCode(lat, lon, function (res, lat, lon) {
        //sometime api returns incorrect zip. So, if user searched for a zip, then show it directly
        var userInput = $zipcodeInput.value;
        if (userInput.length > 0 && /^\d{5}$/.test(userInput.trim())) {
            res = userInput;
        }

        // Global zip tracking
        _satellite.track("global_nav_zipfield_submit"); // Analitycs tracking
        if (typeof window.tapGlobalNav === "function") {
          window.tapGlobalNav("geo-submit", res);
        }

        setZip.updateAll("", res, lat, lon, "true");
    });
  }


  // check if the zipcode is valid
  function callRestService(request, callback) {
    fetch(request, {
      mode: 'cors' // Important for cross-origin requests
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Assuming the response is JSON
      })
      .then(data => {
        callback(data);
      })
      .catch(error => {
        showError();
      });
  }

  // Show errors messages on the modal
  function showError() {
    //nav error box show
    $errorMessage.classList.add('active');
    // add the error class to the input container to add error style
    $zipInputContainer.classList.add('error');
  }


  function getPinCode (lat, lon, callback) {
    callRestService(
      "https://dev.virtualearth.net/REST/v1/Locations/" + lat + "," + lon + "?key=" + bingMapsAPIKey_AutoComplete,
      function (res) {
        if (res.resourceSets["0"].estimatedTotal > 0) {
          callback(res.resourceSets[0].resources[0].address.postalCode, lat, lon);
        } else {
          showError();
        }
      }
    );
  }

  // Handles the key down functionality for accessibility just in the modal
  function handleKeyDown(event) {
    if (event.key !== 'Tab') {
      return; // Not the Tab key, do nothing
    }

    if (event.shiftKey) { // event shift key pressed for shift + tab
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else { // event tab key pressed
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }
  }

  // Clears the zipcode input and delete the error messages when the clear button is clicked
  function clearZipcodeInput() {
    $zipcodeInput.value = '';
    $zipInputContainer.classList.remove('error');
    $errorMessage.classList.remove('active');
  }

  function notifyOtherPage(zipcode) {
    setTimeout(function() {
      if (typeof initPage == "function" || typeof initPage === typeof Function) {
        initPage();
        if (window.platform) {
          window.platform.pe.updateZipCode(zipcode);
        }
      } else {
        debug && console.log("Geo - initPage() Error");
      }
    }, 500);
  }

  // Eevent listeners
  $zipcodeSearchButton.addEventListener('click', searchZipCode);
  $zipcodeInput.addEventListener('keypress', function(e) {
    if (e.key === 'Enter') {
      searchZipCode()
    }
  });
  $zipcodeInput.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      if (localStorage._GeoCurrentZipCode) {
        closeForm();
      } 
    }
  });
  $zipcodeButton.addEventListener('keydown', function(e) {
    if (e.type === 'Enter') {
      openForm();
    }
  });
  $zipcodeButton.addEventListener('click', openForm )
  $zipInputNavClose.addEventListener('click', closeForm);
  $zipInputClearBtn.addEventListener('click', clearZipcodeInput);

  if (localStorage._GeoCurrentZipCode)  {
    $zipcodeButtonSpan.textContent = localStorage._GeoCurrentZipCode;
  } else {
      $zipInputNavClose.classList.add('hidden');
    openForm();
  }

  $zipcodeInput.addEventListener("input", () => {
    $zipcodeInput.value = $zipcodeInput.value.replace(/[^0-9]/g, "");
  });

// Listen for the custom event
window.addEventListener('zipcodeUpdate', (event) => {
  let zipCode = event.detail.split('-')[0]; // Clean up zip code to just 5 digits
  $zipcodeButtonSpan.textContent = zipCode; // update zipcode on the global nav
});


});